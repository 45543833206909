import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import  "./assets/_index.scss";

import BlogBox from "@components/blog-box/";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

class MoreBlogPosts extends React.Component {

    componentDidMount() {
        const boxes = this.container.querySelectorAll('.blog-box');

        gsap.fromTo(boxes, {
            y: -70,
            autoAlpha: 0
        },{
            y: 0,
            autoAlpha: 1,
            duration: 0.6,
            stagger: 0.4,
            scrollTrigger: {
                trigger: this.container,
                start: 'top 70%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        })
        gsap.fromTo(this.title, {
            y: -70,
            autoAlpha: 0
        },{
            y: 0,
            autoAlpha: 1,
            duration: 1.5,
            scrollTrigger: {
                trigger: this.container,
                start: 'top 70%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        })
    }

    render() {
        const teamMember = this.props.teamData.edges;
        const ignoreId = this.props.excludeId ? this.props.excludeId : null;
        
        return (
            <section className={`section section-more-blog-posts${this.props.sectionClass}`}>
                <Container>
                    <Row>
                        <h3 className={this.props.sectionTitleClass} ref={el => { this.title = el; }}>{this.props.sectionTitle}</h3>
                    </Row>
                    <Row ref={el => { this.container = el; }}>
                        {teamMember.map((team, index) => {
                            if (ignoreId === team.node.id) return null;
                            return (
                                <BlogBox
                                    key={team.node.id}
                                    slug={`/help-and-advice/${team.node.category.Slug}/${team.node.Slug}`}
                                    boxClass=" pb-22 pb-md-52 pb-lg-55 col-md-6 col-lg-4"
                                    imgUrl={team.node.BannerImage.Image?.childImageSharp?.fluid}
                                    categorylabelName={team.node.category.labelName}
                                    categoryLabelColour={team.node.category.labelColour}
                                    categoryLabelFontColour={team.node.category.labelFontColour}
                                    Title={team.node.Title}
                                    Text={team.node.articleText ? team.node.articleText.articleText : null}
                                />
                            )
                        })}
                    </Row>
                </Container>
            </section>
        );
    }
}

// Specifies the default values for props:
MoreBlogPosts.defaultProps = {
    sectionClass: ' pt-33 pb-15 pt-md-52 pb-md-0 pt-xl-88 border-top-light',
    sectionTitle: 'More of our team',
    sectionTitleClass: 'section-title col-xs-12 mb-16 mb-md-29 mb-xl-43',
    itemClass: 'more-posts-card-normal d-flex align-items-center flex-md-column align-items-md-start mb-25 mb-md-60 mb-xl-84',
    imgClass: 'more-posts-card__img mb-md-43',
    textItemClass: 'more-posts-card__text mx-30',
    titleClass: 'more-posts-card__title font-weight-bold mb-0 mb-md-9 mb-xl-1'
}

export default (props) => (
    <MoreBlogPosts teamData={props.allStrapiBlogs} excludeId={props.excludeId} sectionTitle={props.sectionTitle} />
)